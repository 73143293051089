iframe#webpack-dev-server-client-overlay {
  display:none !important;

}

.ant-upload-drag {
  height: auto !important;

}

body {
  margin: 0;

}

.signOut:hover {
  color: #1890ff !important;
  cursor: pointer;

}

.signOut svg {
  margin-bottom: 1px;

}

.ant-menu-item .anticon + span {
  margin-left: 5px;

}

h1 {
  margin-top: 0;

}

.action-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px -5px 0;
  justify-content: center;

}

button.action-list-button {
  flex: 1 auto 100%;
  margin: 0;

}

span.action-list-button {
  flex: 1;
  margin: 5px 0;

}

.action-list button {
  flex: 1 0 100%;
  margin: 5px 0 5px 0;

}

.action-list-button > button {
  width: 100%;
  margin: 0;

}

.ant-alert-description {
  white-space: pre-wrap;

}

.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 328px !important;

}

::-webkit-scrollbar {
  width: 7px;

  -webkit-appearance: none;

}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);

}

.x-scroll {
  overflow-x: scroll;

}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #e6f7ff;

}

.ant-dropdown-trigger span:hover,
.ant-table-filter-trigger:hover {
  color: #bfbfbf;
}

.ant-table-filter-trigger.active,
.ant-table-filter-trigger.active span {
  color: #1890ff;
}

.ant-btn-link:hover {
  cursor: pointer;
}
