.package-uploader-row {
  margin-bottom: 21px;

}

.package-uploader-row > span > .ant-upload-list {
  position: absolute !important; 

}

.packages-input-number.ant-input-number {
  width: 180px;

}

td.packagesDescriptionSelenium {
  max-width: 320px;

}

td.packagesIdSelenium, td.packagesNameSelenium, td.packagesSkuSelenium {
  max-width: 150px;

}
