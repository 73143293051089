td.depositDetailBarcodeIdSelenium {
  max-width: 150px;

}

td.depositDetailBarcodeValueSelenium {
  max-width: 210px;

}

td.depositDetailProductNameSelenium {
  max-width: 300px;

}

td.ant-descriptions-item-content {
  word-break: break-word;

}

.retailersList span span:not(:empty):not(:last-child):after {
  content: ", ";
}
