td.categoryIdSelenium {
  max-width: 150px;

}

td.categoryTitleSelenium, td.categoryParentIdSelenium {
  max-width: 290px;

}

.ant-tree-node-content-wrapper {
  width: 100%;
  padding: 0!important;
}

.ant-tree-treenode {
  width: 100%;
  padding: 0!important;
}

.ant-tree-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-tree-list-holder-inner .root-category {
  padding: 7px;
  font-size: 15px;
  display: flex;
}

.root-category .icon-wrapper {
 margin-left: 30px;
}

.tree-node-title {
  display: flex;
  padding: 7px;
}

.tree-node-title .icon-wrapper {
  margin-left: 40px;
}

.tree-node-title span {
  font-size: 16px;
}

.ant-tree-list-holder-inner .root-category .icon-wrapper span {
  font-size: 18px;
}

.ant-tree-list-holder-inner .root-category .icon-wrapper span:first-of-type {
  margin-right: 10px;
}

.ant-tree-node-content-wrapper:hover {
  background-color: transparent !important;
}

.ant-tree-node-selected {
  background-color: transparent !important;
}

.ant-tree .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 15px;
}

.ant-tree-switcher_open + span, 
.ant-tree-switcher_close + span {
  font-weight: bold;
}
